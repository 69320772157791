/* Tables */

.table {
  margin-bottom: 0;
  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-weight: $font-weight-medium;
      i{
        margin-left: 0.325rem;
      }
    }
  }
  th,
  td {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;
    white-space: nowrap;
    padding: $table-cell-padding;
    img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
    }
    .badge {
      margin-bottom: 0;
    }
  }
  &.table-borderless{
    border: none;
    tr,td,th{
      border: none;
    }
  }
  &.select-table {
    tr {
      td {
        &:first-child {
          padding-right: 10px;
        }
      }
    }
    th {
      color: $select-table-head;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 15px;
      text-transform: uppercase;
      padding-right: 5px;
      padding-left: 0;
      .form-check {
        margin-bottom: 0;
        width: 20px;
      }
    }
    td {
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $select-table-cell-color;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 0;
      .form-check {
        width: 20px;
      }
      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: $black;
        margin-bottom: 5px;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $text-muted;
        margin-bottom: 0;
      }
      img {
        width: 44px;
        height: 44px;
        border-radius: 10px;
        margin-right: 15px;
        .rtl & {
          margin-right: 0;
          margin-left: 15px;
        }
      }
      .max-width-progress-wrap {
        width: 120px;
      }
      .form-check {
        margin-bottom: 0;
      }
    }
  }
}
