/* Utilities */

.grid-margin {
  margin-bottom: $card-spacing-y;
}

.grid-margin-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}

.grid-margin-md-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.grid-margin-lg-0 {
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}

.grid-margin-xl-0 {
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}

.img-lg {
  width: 92px;
  height: 92px;
}

.img-sm {
  width: 43px;
  height: 43px;

  &.rounded-10 {
    border-radius: 10px;
  }
}

.img-xs {
  width: 37px;
  height: 37px;
}

.img-ss {
  width: 35px;
  height: 35px;
}

.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);

  >.card {
    width: 100%;
    min-width: 100%;
  }
}

.border-right-sm {
  @media (min-width: 576px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-md {
  @media (min-width: 768px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-lg {
  @media (min-width: 992px) {
    border-right: $border-width solid $border-color;
  }
}

.border-left-sm {
  @media (min-width: 576px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-md {
  @media (min-width: 768px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-lg {
  @media (min-width: 992px) {
    border-left: $border-width solid $border-color;
  }
}

.text-gray {
  color: #8c8c8c;
}

.text-black {
  color: $black;
}

.text-small {
  font-size: 12px;

  &.ps-4 {
    padding-left: 30px !important;
  }
}

.flex-grow {
  flex-grow: 1;
}

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.font-weight-normal {
  font-weight: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.image-grouped {
  display: flex;

  .text-avatar,
  img {
    @extend .img-ss;
    border-radius: 100%;
    margin-left: -10px;
    z-index: 0;
    border: 4px solid $card-bg;
    transform: scale(1);
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-property: "box-shadow", "z-index", "transform", "border-width";

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      z-index: 1;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      transform: scale(1.05);
      border-width: 0;

    }
  }

  .text-avatar {
    @extend .bg-inverse-primary;
    color: $primary;
    font-size: 11px;
    font-weight: 600;
  }
}

.dot-indicator {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.aligner-wrapper {
  position: relative;

  .absolute {
    position: absolute;

    &.absolute-center {
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.bottom {
      bottom: 0;
    }

    &.top {
      top: 0;
    }
  }
}

.v-strock-2 {
  width: 3px;
}

.min-width-cell {
  min-width: 110px;
}

.font-weight-medium {
  font-weight: 500;
}

.media {
  display: flex;
}

.settings-nav-bottom {
  position: fixed;
  bottom: 10px;
  width: 220px;
}

.h-250 {
  height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.list.active {
  background: #ffe2e2;
  box-shadow: 0 3px 4px #c2c2c2;
}

.list {
  .dropdown {
    .dropdown-toggle:after {
      content: none;
    }
  }
}

.z-0 {
  z-index: 0 !important;
}



.shadow-light {
  box-shadow: 0 0 5px rgb(154 154 154 / 43%) !important;
}

.border-radius-5 {
  border-radius: 5px;
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
  margin: 0;
}

.MuiTypography-root {
  font-family: "Lato", sans-serif;
}

.zoom-on-hover {
  transition: transform 0.3s ease-in-out;
  /* Smooth transition effect */
}

.zoom-on-hover:hover {
  transform: scale(1.05);
  /* Zoom in 1.1 times */
}

select {
  &.form-control {
    color: #000 !important;
  }
}

.bg-red {
  background: #ffdfda;
}

.bg-orange {
  background: #fce4bd;
}


.react-images__view-image.react-images__view-image--isModal {
  height: 100vh;
}

tr.MuiTableRow-root {
  height: inherit !important;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 100;
  /* Adjust this value to ensure the element is above the rest */
}

.sidebar {
  position: fixed;

}

.sidebar-icon-only .main-panel {
  margin-left: 70px !important;
}

.main-panel {
  margin-left: 220px;
}


.icon-size {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 1.5rem;
}

.form-select {
  height: 48px;
  margin-bottom: 10px;
}

.margin-bottom-5 {

  margin-bottom: 5px;
}

.full-width-btn-group .btn-group {
  width: 100%;
}

.ellipsis {
  white-space: nowrap !important;
  /* Ensure the text is on one line */
  overflow: hidden;
  /* Hide the overflow text */
  text-overflow: ellipsis;
  /* Add ellipses at the end of the overflow */
  max-width: 100%;
  /* Set a max-width to contain the content within the parent */
}

.f-30 {
  font-size: 30px !important;
}

.f-20 {
  font-size: 20px !important;
}


.btn-group {
  display: flex;
  justify-content: flex-start;
}

.custom-chart .btn-group {
  display: flex;
  justify-content: flex-start;
  background-color: #ddd;
  /* Background color to simulate the space between buttons */
  position: absolute;
  right: 29px;
  top: 80px;
  z-index: 10000;
}

.custom-chart .btn {
  padding: 8px 16px;
  margin: 0;
  /* Remove margin to have buttons side by side */
  border: none;
  /* Remove all borders initially */
  border-right: 1px solid #ddd;
  /* Add right border to separate the buttons */
  background-color: #f8f8f8;
  cursor: pointer;
  transition: background-color 0.3s ease;
  line-height: 1;
  /* Adjust line height as needed for vertical alignment */
}

.custom-chart .btn:first-child {
  border-top-left-radius: 4px;
  /* Rounded corner for the first button */
  border-bottom-left-radius: 4px;
}

.custom-chart .btn:last-child {
  border-right: none;
  /* Remove right border for the last button */
  border-top-right-radius: 4px;
  /* Rounded corner for the last button */
  border-bottom-right-radius: 4px;
}


.custom-chart .btn:hover {
  background-color: #e0e0e0;
}

.custom-chart .btn-primary {
  color: white;
  background-color: #262626;
  border-color: #262626;
}

.custom-chart .btn-primary:active,
.custom-chart .btn-primary:focus {
  color: white;
  background-color: #262626 !important;
  border-color: #262626 !important;
  outline: none !important;
}

.custom-chart .btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
}

.nav-tabs .nav-link.active {
  border-color: #d62828;
  /* Red border for active tab */
  background-color: #d62828;
  /* Red background for active tab */
  color: #ffffff;
  /* White text color for better visibility */
}

.nav-pills .nav-link.active {
  background-color: #d62828;
  /* Red background for active pill */
  color: #ffffff;
  /* White text color for better visibility */
  border-bottom: 4px solid #646464;
}

.nav-tabs .nav-link,
.nav-pills .nav-link {
  color: #d62828;
  /* Red text color for inactive tabs/pills */
}

.nav-tabs .nav-link:hover,
.nav-pills .nav-link:hover {
  background-color: #ffcccc;
  /* Light red background on hover */
}

.color-circle {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 10px;
  border-radius: 10px;
}

.form-check .form-check-input {
  float: left;
  margin-left: 0em;
}

.responsive-table .table {
  width: 100%;
  overflow-x: auto;
  /* Ensures table is scrollable horizontally */
}

.wrapped-text {
  word-break: break-word;
  /* Use 'break-all' if you want to break at any character */
}

.audit-tab .nav-tabs .nav-link,
.nav-pills .nav-link {
  color: #fff;
}

.custom-flex-container {
  display: flex;
  justify-content: space-between;
  /* This will justify the pills across the container */
  margin-bottom: 10px;
  /* Space between rows */
  width: 100%;
}

.custom-flex-container .nav {
  flex: 1;
  /* Allows the nav to fill the container */
  border-bottom: none;
}

.custom-flex-container .custom-flex-item {
  flex: 1;
  /* Each item takes equal space */
  display: flex;
  /* Use Flexbox for the alignment of the link inside the item */
  justify-content: center;
  /* Center the pill inside the item */
}

.custom-flex-container .nav-link {
  width: 100%;
  /* Ensure the link fills the item */
}

.sticky-nav {
  position: sticky;
  top: 0;
  z-index: 1000;

}

.nested-modal {
  --bs-backdrop-zindex: 1055;
  z-index: 1055;
}


//table css

/* Style for the table */
.table-class {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Style for the table header */
.table-class thead tr {
  background-color: #F0F2F4;
  color: black;
  text-align: left;
  padding: 10px 0;
}

.table-class td ul>li {
  font-size: 1.1em;
}

/* Style for the table header cells */
.table-class th,
.table-class td {
  padding: 12px 15px;
  border: none;
}

/* Style for the table rows */
.table-class tr {
  border-bottom: 1px solid #dddddd;
}

/* Style for the table row when hovered */
.table-class tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.table-class tr:last-of-type {
  border: none;
}

/* Style for the table data cells */
.table-class td {
  // border-right: 1px solid #dddddd;
}

.table-class td:last-child {
  border-right: none;
}

/* ComplianceTable.module.css */
.tableContainer {
  width: 100%;
  margin: 0 auto;
  overflow-x: auto;
}

.complianceTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Arial, sans-serif;
  background: #d62828;
  color: white;
}

.complianceTable thead {
  background-color: #f2f2f2;
}

.complianceTable th,
.complianceTable td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}

.complianceTable th {
  font-weight: bold;
}

.complianceTable tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.complianceTable ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.logout-icon {
  font-size: 30px;
}

.action-card:hover {
  background: #e9e9e9;
  cursor: pointer;
}

.action-card {
  margin-bottom: 33px;
}

.card .card-title {
  color: #010101;
  margin-bottom: 1.2rem;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1.375rem !important;
}

.action-card h1,
.action-card h4 {
  color: black;
  text-decoration: none;
  border: none;
}

.no-link {
  text-decoration: none !important;
}

body {
  font-family: "Lato", sans-serif !important;
}

.useremail {
  font-size: 16px;
  margin-left: 15px;
}

.name-icon {
  width: 40px;
  height: 40px;
  color: #FFF;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  font-variant-caps: all-small-caps;
  padding: 7px;
}

.cardShadow {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background: #ffffff;
  padding: 15px;
  cursor: pointer;
}

.cardShadow:hover {
  box-shadow: 0px 5px 44px 0px rgba(0, 0, 0, 0.25);
  transition: .4s;

}

.cardCount {
  font-size: 46px;
  color: #101828;
  font-weight: 600;
}

.cardTitle {
  font-size: 22px;
  color: #315975;
  font-weight: 600;
}

.cardDesc {
  font-size: 14px;
  color: #101828;
  font-weight: 400;
  margin-bottom: 45px;
}

.cardLink {
  font-size: 14px;
  color: #363F72;
  font-weight: 600
}

.actionDesc {
  font-size: 14px;
  color: #101828;
}

.actionTitle {
  font-size: 20px;

}

.search {
  position: relative;
  color: #aaa;
  font-size: 16px;
}

.search {
  display: inline-block;
}

.search input {
  width: 320px;
  height: 44px;
  background: #fcfcfc;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

}

.search input {
  text-indent: 32px;
}

.search .fa-search {
  position: absolute;
  top: 14px;

}

.search .fa-search {
  left: 14px;
  right: auto;
}

.searchbox .filter {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  background-color: #D0D5DD !important;
  color: #3E4451;
}

.searchbox .filter:focus,
.searchbox .filter:active,
.searchbox .filter:hover {
  border: none;
  color: #3E4451;
}

.nav-item.nav-profile .dropdown .dropdown-toggle:after {
  display: none !important;
}

.option-list label {
  padding: 10px;
  display: flex;
}

.bg-background-cover {
  background: url('../images/cover.jpg');
  background-size: cover;
  position: absolute;
  width: 100%;
  left: -65px;
  right: 0;
  height: 100%;

}


//New UI CSS

.useremail {
  font-size: 16px;
  margin-left: 15px;
}

.name-icon {
  width: 40px;
  height: 40px;
  color: #FFF;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  font-variant-caps: all-small-caps;
  padding: 7px;
}

.cardShadow {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background: #ffffff;
  padding: 15px;
  cursor: pointer;
}

.cardShadow:hover {
  box-shadow: 0px 5px 44px 0px rgba(0, 0, 0, 0.25);
  transition: .4s;

}

.cardCount {
  font-size: 46px;
  color: #101828;
  font-weight: 600;
}

.cardTitle {
  font-size: 22px;
  color: #315975;
  font-weight: 600;
}

.cardDesc {
  font-size: 14px;
  color: #101828;
  font-weight: 400;
  margin-bottom: 45px;
}

.cardLink {
  font-size: 14px;
  color: #363F72;
  font-weight: 600
}

.actionDesc {
  font-size: 14px;
  color: #101828;
}

.actionTitle {
  font-size: 20px;

}

.search {
  position: relative;
  color: #aaa;
  font-size: 16px;
}

.search {
  display: inline-block;
}

.search input {
  width: 320px;
  height: 44px;
  background: #fcfcfc;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

}

.search input {
  text-indent: 32px;
}

.search .fa-search {
  position: absolute;
  top: 14px;

}

.search .fa-search {
  left: 14px;
  right: auto;
}

.searchbox .filter {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  background-color: #D0D5DD !important;
  color: #3E4451;
}

.searchbox .filter:focus,
.searchbox .filter:active,
.searchbox .filter:hover {
  border: none;
  color: #3E4451;
}

.nav-item.nav-profile .dropdown .dropdown-toggle:after {
  display: none !important;
}

.option-list label {
  padding: 10px;
  display: flex;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  border: 1px solid #315975 !important;
  padding: 13px 24px 13px 24px;
  border-radius: 8px 8px 0px 0px;
  color: #315975 !important;
  font-weight: 700;

}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
  text-transform: unset !important;
  padding: 13px 24px 13px 24px;
  font-family: unset;
  color: #828282 !important;
}

body {
  font-family: "Lato", sans-serif !important;
}

.custom-dropdown {
  width: 380px !important;
}

.fil-h4 {
  font-size: 20px;
  color: #101828;
  border-bottom: 1px solid #D5D5D5;
}

.fil-h3 {
  font-size: 16px;
  color: #101828;

}

.type-fil {
  border-bottom: 1px solid #D5D5D5;
}

.loadmore {
  color: #005284;
  font-size: 14px;
  font-weight: 900;
}

.maskid {
  color: #315975;
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {

  font-weight: 700;
  text-align: left !important;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
  text-transform: unset !important;
  font-family: "Lato", sans-serif;
  color: #828282 !important;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

}

.vertical-tabs button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
  min-height: unset !important;
}

.medium-modal .modal-lg {
  --bs-modal-width: 50% !important;
}

.obs-content,
.obs-title {
  font-size: 16px;

  font-weight: bold;
}

.obs-title {
  color: darkgray;
}

.obs-header {
  background-color: #F9FAFB;
}

.obs-section {
  border-bottom: 1px solid #eee;
}

.MuiTableCell-head {
  background: #d4d4d4;
}

.MuiTableCell-body {
  padding: 10px 10px !important;
}

.maskid .pending {
  height: 16px;
  width: 16px;
  border-radius: 50px;
  display: inline-block;
  background: rgba(251, 191, 36, 1);


}

.maskid .overdue {
  height: 16px;
  width: 16px;
  border-radius: 50px;
  display: inline-block;
  background: rgba(238, 87, 36, 1);


}

.table-action i {
  font-size: 25px;
  cursor: pointer;
  padding: 0px 5px;
}

.p-column-filter-overlay,
.p-multiselect-panel {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.p-component {
  font-family: "Lato", sans-serif !important;
}

.headtitle {
  border-left-width: 2px;
  border-left-color: rgba(209, 213, 219, 1);
  border-left-style: solid;

}

.p-tag.p-tag-warning {
  background-color: #e29e09;
  color: #fff;
}

.p-tag {

  width: 72px;
}

.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50% !important;
  height: 2.357rem;
}

.p-datatable-header {
  background-color: #ffffff;
}

.headerCount {
  background: #8d90937a;
  color: #fff;
  border-radius: 4px;
  padding: 6px;
  font-weight: bold;
  margin-left: 5px;
  font-size: 13px;
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected .headerCount {
  background-color: #005284;
}

span.MuiTabs-indicator {
  background-color: #005284 !important;
  height: 3px !important;

}

.calicon button {
  background-color: #005284;
  border: 1px solid #005284;
}

.react-photo-gallery--gallery>div>img {
  flex-flow: nowrap !important;
  width: 150px;
  height: 150px;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected .headerCount {
  background-color: #005284;
}

span.MuiTabs-indicator {
  background-color: #005284 !important;
  height: 3px !important;

}

.calicon button {
  background-color: #005284;
  border: 1px solid #005284;
}

.obs-head-title {
  color: rgba(16, 24, 40, 1);
  font-size: 24px;
  font-weight: 600;

}

.obs-id {
  font-size: 16px;
  color: rgba(55, 65, 81, 1);
  font-weight: 600;
}

.obs-head-dec {
  font-size: 14px;
}

.obs-head-color {
  color: rgba(16, 24, 40, 1);
}

.obs-dec {
  font-size: 16px;
}

.p-button {
  border-radius: 3px !important;
}

.dashboard-filter {
  width: 250px;
  /* or any other fixed width */
  margin: 0 10px;
  /* Add some horizontal spacing */
}

.right-content {
  position: absolute;
  top: 80px;
  right: 125px;
  background: black;
  color: white;
  padding: 3px 10px;
  z-index: 10;
}

.right-content p {
  font-size: 14px;
}

.table td {
  white-space: break-spaces !important;

  body {
    color: rgba(16, 24, 40, 1) !important;

  }
}

.p-card {
  border: 1px solid rgba(208, 213, 221, 1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}

.apply-btn {
  background: #005284;
  color: #FFF;
}

.clear-btn {
  border: 1px solid #005284;
  color: #005284;
}

.opacity-div {
  opacity: 0;
}

.opacity-clear {
  opacity: 1;
  transition: opacity 0.5s linear;
}

.loc-box {
  padding: 2px 9px 2px 9px;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  font-size: 14px;
  color: #344054;
  background: #fff;
}



.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-horizontal-margin {
  margin-left: 0;
  margin-right: 0;
}

.location-info {
  display: flex;
  flex-direction: column;
}

.location-title {
  padding: 10px;
  margin-top: 5px;
  border-radius: 6px;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.blue-bg {
  background-color: #005284;
  color: white;
}

.light-bg {
  background-color: #E1EBEE;
}

.isolation-info {
  margin-top: 5px;
}

.isolation-item {
  margin-top: 15px;
}

.isolation-question {
  display: block;
  text-align: justify;
  margin-right: 20px;
  font-size: 16px;
}

.isolation-answer {
  display: block;
  margin-top: 5px;
  font-weight: bold;
}

.green {
  color: #006400;
}

.red {
  color: #D62828;
}

.orange {
  color: #ffa500;
}

.required-star {
  color: red;
}

.text-header {
  font-family: Montserrat, sans-serif;
  /* Use the font of your choice */
}

.na {
  padding-top: 5px;
  padding-left: 5px;
  font-size: 18px;
}



.text-header {
  font-family: 'Montserrat', sans-serif;
  /* Make sure the font is included in your project */
  margin-bottom: 10px;
}

.list-item,
.checklist-item,
.activity-question,
.activity-answer {
  margin: 5px 0;
  padding-left: 20px;
}

.sso-background {
  background-color: #005284;
  /* Adjust color as needed */
  color: white;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.safety-checklist-header {
  font-weight: bold;
  font-size: 16px;
}

.safety-checklist-subheader {
  font-size: 14px;
  text-align: center;
}

.activity-confirmation {
  padding-vertical: 10px;
  margin-top: 5px;
}

.activity-answer {
  font-weight: bold;
  color: function that determines color based on the label;
  /* Implement a function if needed */
}

.box {
  padding: 10px;
  border: 1px solid #ddd;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}


.text-header {
  margin-bottom: 5px;
  font-size: 18px;
}

.info {
  font-size: 14px;
  margin-bottom: 5px;
  text-align: justify;
}

.p-accordion-header a {
  text-decoration: none;
  color: #010101;
}

.status-tag-orange {
  background: rgba(249, 223, 127, .2);
  color: #eb961d;
}

.status-tag-pink {
  background: rgba(216, 149, 218, .12549019607843137);
  color: #d895da;
}

.status-tag-blue {
  background: rgba(189, 209, 247, .5);
  color: #5b8ff7;
}

.greenBox {
  background-color: #92D14F;
  color: #3E7A18;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
}

.orangeBox {
  background-color: #FFDE91;
  color: #CE823C;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
}

.redBox {
  background-color: #FEA9A4;
  color: #E42120;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
}

.findings-container {
  display: flex;
  justify-content: space-around;
  /* Distributes space evenly between the items */
  background-color: #00a8cf0c;
  /* Light gray background */
  padding: 20px;
  /* Adds padding around the container */
  // box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Adds subtle shadow around the container */
  border-radius: 5px;
  /* Rounds the corners */
  margin-top: 10px;
  margin-bottom: 10px;
}

.finding {
  flex: 1;
  /* Each finding takes equal width */
  text-align: left;
  /* Centers the text inside each finding */
  padding: 10px;
  /* Adds padding inside each finding block */
  margin: 0 10px;
  /* Adds horizontal margin between finding blocks */
}

.finding-title {
  font-size: 16px;
  /* Sets the font size for the title */
  font-weight: bold;
  /* Makes the title bold */
  margin-bottom: 5px;
  /* Adds a small space below the title */
}

.finding-count {
  font-size: 14px;
  /* Sets the font size for the count */
}

.score-details-container {
  display: flex;
  justify-content: space-around;
  /* This will evenly space the boxes */
  flex-wrap: wrap;
  /* Allows items to wrap in smaller screens */

  margin: 0 -20px;
}

.score-box {
  width: 22%;
  /* Sets a width for each box */
  padding: 20px;
  margin: 10px;
  background-color: white;
  /* White background for the boxes */
  border: 1px solid #ccc;
  /* Light grey border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
}

.score-box h4 {
  margin-top: 0;
  color: #333;
  /* Dark grey color for text */
}

.score-box p {
  font-weight: bold;
}

.custom-nav.nav-pills .nav-link {
  border: none;
  border-left: 5px solid;
  /* Default state with transparent border */
  transition: box-shadow 0.3s ease-in-out;
  /* Smooth transition for the shadow */
  color: #000;
  box-shadow: 0px 0px 3px #ababab;
  border-radius: 4px;
}

.custom-nav .nav-link.active {
  position: relative;
  box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.2);
  border: none;
  border-color: inherit;
  border-left: 5px solid;
  border-radius: 4px;
  background-color: white;
  color: #000;
}

.custom-nav .nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  /* Positioning the arrow below the tab */
  left: 50%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ffffff;
  /* Arrow color */
  transform: translateX(-50%);
  /* Centering the arrow */
}

.custom-nav-2.nav-pills .nav-link {
  border: none;
  border-left: 5px solid;
  /* Default state with transparent border */
  transition: box-shadow 0.3s ease-in-out;
  /* Smooth transition for the shadow */
  color: #000;
  box-shadow: 0px 0px 3px #ababab;
  border-radius: 4px;
  background-color: rgba(215, 216, 215, 0.3);
}

.custom-nav-2 .nav-link.active {
  position: relative;
  box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.2);
  border: none;
  border-color: inherit;
  border-left: 5px solid;
  border-radius: 4px;
  background-color: white;
  color: #000;
}

.custom-nav-2 .p-checkbox .p-checkbox-box.p-highlight {
  border-color: black;
  background: black;
  color: white;
  border-radius: 20px;
}

.custom-nav-2 .p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 20px;
}

.custom-link {
  color: rgba(49, 89, 117, 1);
  font-weight: 700;

}

.border-none {
  border: none !important;
}

.abs-top {
  position: absolute;
  top: 50px;
}

.initiated-cell {
  background-color: #e0f7fa;
  /* Light blue background */
  color: #00796b;
  /* Darker blue-green text */
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
}

.card-eptw {
  padding: 3px 8px;
  background: rgb(188 196 255);
  border-radius: 50px;
  font-size: 10px;
  color: #2323d7;
}

.sub-menu .nav-item.active a {
  color: #d62828 !important;
}

.custom-datatable .p-datatable-header {
  background-color: #FCE8EA;
  /* Prominent color for header */
  color: black;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  padding: 10px;
}

.custom-datatable .p-datatable-thead>tr>th {
  background-color: #FCE8EA;
  /* Match header background */
  color: black;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border-right: 1px solid #ddd;
  /* Add column lines */
}

.custom-datatable .p-datatable-tbody>tr>td {
  padding: 8px;
  word-break: break-word;
  /* Ensure word break */
  border-right: 1px solid #ddd;
  /* Add column lines */
}

.custom-datatable .p-datatable-tbody>tr.p-row-odd {
  background-color: #f9f9f9;
}

.custom-datatable .p-datatable-tbody>tr.p-row-even {
  background-color: #ffffff;
}

.custom-datatable .p-datatable-tbody>tr:last-child>td {
  border-bottom: 1px solid #ddd;
  /* Add bottom border for last row */
}

.reported-by {
  position: relative;
  cursor: pointer;
}

.reported-by::after {
  content: attr(title);
  position: absolute;
  left: 0;
  bottom: 100%;
  white-space: nowrap;
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  z-index: 999;
}

.reported-by:hover::after {
  opacity: 1;
}

.break-word {
  word-break: break-word;
}

.invalid-field {
  border: 1px solid red;
}

.publicObs-form {
  position: fixed;
  left: 0;
  height: 100vh !important;
  overflow: scroll;
  background: white;
  margin: 15px;
  width: 100%;
  top: -25px;
}

.react-datepicker .react-datepicker__month-container {
  float: left !important;
}

/* Define the z-index for the date picker pop-up */
.custom-datepicker .react-datepicker-popper {
  z-index: 1050 !important;
  /* Set a higher z-index value */
}

.react-datepicker-popper {
  z-index: 3 !important;
}

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

.boxShadow {
  box-shadow: 0 0 12px 3px #e8e8e8;
  cursor: pointer;
}

.selected-card {
  background-color: #ffe9c4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease-in-out;
}
